import React, {useEffect, useState} from 'react';
import './EditableIntroSurvey.scss';
import {useNavigate, useParams} from "react-router-dom";
import SurveyHero from "../../../../components/Survey/SurveyHero/SurveyHero";
import {deletePreviousSurveyData, deleteSurveyData, updateSurveyPage} from "../../../../../redux/actions/survey";
import {useDispatch} from "react-redux";


const EditableIntroSurvey = ({showGlobalSnackbar, survey, i18n, app, isNext}) => {

    const navigate = useNavigate();
    const {code} = useParams();
    const {previousId} = useParams();
    const {surveyId} = useParams();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateSurveyPage(0));
        dispatch(deleteSurveyData());
        dispatch(deletePreviousSurveyData());
        window.scrollTo(0, 0)
    }, []);


    const nextPage = () => {
        dispatch(updateSurveyPage(1));
        if (isNext) {
            navigate("/survey-next-edit/" + code + "/" + previousId + "/health-concerns");

        } else {
            navigate("/survey-edit/" + code +  "/" + surveyId + "/patient-profile");
        }
    }


    return (
        <div className="FirstScreenSurvey">

            <SurveyHero
                step={i18n.t("hello")}
                headline={survey?.settings?.introHeadline}
                text={survey?.settings?.introText}
                videoLink={survey?.settings?.introVideo}
                buttonText={i18n.t("updateSurvey")}
                clickedButton={() => nextPage()}
                app={app}
                surveyType={survey?.settings?.type}
            />


        </div>
    );
};

export default EditableIntroSurvey;
