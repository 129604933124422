import React from "react";
import "./Accordion.scss";
import i18next from "../../../../i18next";

const Accordion = ({title, children, isChild = false, clickable = true}) => {
    const [isOpen, setOpen] = React.useState(false);
    const [showNotClickable, setShowNotClickable] = React.useState(false);

    const openAndCloseNotClickable = () => {
        setShowNotClickable(true);
        setTimeout(() => {
            setShowNotClickable(false);
        }, 1000)
    }

    return (
        <div
            className={`accordion-wrapper ${isChild ? "accordion-child" : ""} ${!clickable ? "accordion-not-clickable" : ""}`}>
            <div title={!clickable ? i18next.t("comingSoon") : null}
                 className={`accordion-title ${isOpen ? "open" : ""} ${isChild ? "accordion-title-child" : ""}`}
                 onClick={() => clickable ? setOpen(!isOpen) : openAndCloseNotClickable()}
            >
                {title}
            </div>
            {showNotClickable && !clickable ? <div className={"accordion-not-clickable-item"}>{i18next.t("comingSoon")}</div>
                : null}
            <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
                <div className="accordion-content">{children}</div>
            </div>
        </div>
    );
};

export default Accordion;
