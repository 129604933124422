import React, { useEffect, useMemo, useState } from "react";
import "./SurveyHero.scss";
import Button from "../../Comon/Button/Button";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import { useNavigate, useParams } from "react-router-dom";
import getThemes from "../../../../constants/colors/getTheme";
import TextInput from "../../Comon/TextInput/TextInput";
import { useTranslation } from "react-i18next";
import { isSurveyEditable } from "../../../../core/axios/services";

const SurveyHero = ({
  app,
  step,
  headline,
  text,
  buttonText,
  videoLink,
  surveyType,
  clickedButton,
  isForm,
  i18n,
  name,
  setName,
  lastName,
  setLastName,
  email,
  setEmail,
  isEditable,
  code,
  id,
}) => {
  const [videoSize, setVideoSize] = useState({ width: 600, height: 405 });
  const [textClass, setTextClass] = useState("text-only");
  const [textBodyClass, setTextBodyClass] = useState("text");
  const [emailError, setEmailError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [isCheckedClinic, setIsCheckedClinic] = useState(false);
  const navigate = useNavigate();
  const themeColor = useMemo(() => {
    return getThemes("mainColor", app.appTheme);
  }, [app.appTheme]);
  const { i19n } = useTranslation();

  const link = localStorage.getItem("link");

  useEffect(() => {
    adjustVideoSize();

    function handleResize() {
      adjustVideoSize();
    }

    window.addEventListener("resize", handleResize);
  }, []);

  const adjustVideoSize = () => {
    if (window.screen.width < 641) {
      setVideoSize({
        width: 297,
        height: 200,
      });
    } else {
      setVideoSize({
        width: 600,
        height: 405,
      });
    }
  };

  const changeEmail = (value) => {
    setEmail(value);
  };

  const checkEmail = (value) => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(value)) {
      setEmailError(i18n.t("emailFormat"));

      return false;
    } else {
      setEmailError(null);
      return true;
    }
  };

  const changeName = (value) => {
    setName(value);
  };

  const checkName = (value) => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value === "") {
      setNameError(i18n.t("nameRequired"));

      return false;
    } else {
      setNameError(null);
      return true;
    }
  };

  const changeLastName = (value) => {
    setLastName(value);
  };

  const checkLastName = (value) => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value === "") {
      setLastNameError(i18n.t("lastNameRequired"));

      return false;
    } else {
      setLastNameError(null);
      return true;
    }
  };

  const clickedCTA = () => {
    if (isForm) {
      const isNameValid = checkName(name);
      const isLastNameValid = checkLastName(lastName);
      const isEmailValid = checkEmail(email);

      if (isNameValid && isLastNameValid && isEmailValid) {
        clickedButton({ email, name, lastName });
      }
    } else {
      clickedButton();
    }
  };

  useEffect(() => {
    if (
      surveyType === 2 &&
      videoLink != null &&
      videoLink !== "" &&
      videoLink.trim.length > 0
    ) {
      setTextClass("text-only left-text");
      setTextBodyClass("text text-left");
    } else {
      setTextClass("text-only");
      setTextBodyClass("text");
    }
  }, [surveyType, videoLink]);
  return (
    <div className="SurveyHero">
      <div className={"centeredDiv"}>
        <div className={textClass}>
          <div className={"helloDiv"} style={{ borderColor: themeColor }}>
            {step}
          </div>
          <div className={"headline"}>{headline}</div>
          {/* {isForm && (
            <p style={{ margin: 0 }} className="paragraphLine">
              {i18n.t("editPatientName")}
            </p>
          )} */}
          {isForm ? (
            <div className={"heroForm"}>
              <TextInput
                mode={"grey"}
                type={"text"}
                value={name}
                setValue={changeName}
                errorMessage={nameError}
                disabled={isEditable}
                label={i18n.t("firstName")}
                placeholder={i18n.t("typeHere")}
              />
              <TextInput
                mode={"grey"}
                type={"text"}
                value={lastName}
                setValue={changeLastName}
                errorMessage={lastNameError}
                disabled={isEditable}
                label={i18n.t("lastName")}
                placeholder={i18n.t("typeHere")}
              />
              <TextInput
                mode={"grey"}
                type={"text"}
                value={email}
                setValue={changeEmail}
                disabled={isEditable}
                errorMessage={emailError}
                label={i18n.t("emailAddress")}
                placeholder={i18n.t("typeHere")}
              />
              <p className="privacyTerms surveyPolicy">
                {i18n.t("termsPrivacy")}
                <br /> {i18n.t("tothe")}{" "}
                <a href="/privacy" target="_blank">
                  {i18n.t("privacypolicy")}
                </a>{" "}
                {i18n.t("and")} <a href="/terms">{i18n.t("terms")}</a>
              </p>
            </div>
          ) : (
            <div className={textBodyClass}>{text}</div>
          )}
          {isForm && (
            <label style={{ marginTop: "15px" }}>
              <p className="privacyTerms surveyPolicy">
                <input
                  type="checkbox"
                  checked={isCheckedClinic}
                  onClick={() => setIsCheckedClinic(!isCheckedClinic)}
                />{" "}
                {i18n.t("termsInfo")} {" "}
                <a href={link} target="_blank">
                  Data Processing
                </a>{" "}
                {i18n.t("termsInfo2")}
              </p>
            </label>
          )}

          {buttonText != "" && !isForm ? (
            <div className={"presentContainer"} onClick={() => clickedCTA()}>
              <Button color={themeColor} mode={"small"} text={buttonText} />
            </div>
          ) : isCheckedClinic ? (
            <div className={"presentContainer"} onClick={() => clickedCTA()}>
              <Button color={themeColor} mode={"small"} text={buttonText} />
            </div>
          ) : (
            isForm && (
              <div className={"presentContainer"}>
                <Button
                  color={themeColor}
                  mode={"small"}
                  text={buttonText}
                  disabled
                />
              </div>
            )
          )}
        </div>

        {videoLink != null && videoLink !== "" && isForm !== true ? (
          <VideoPlayer
            url={videoLink}
            themeColor={themeColor}
            videoSize={videoSize}
            id="SurveyHomePage"
          />
        ) : null}
      </div>
    </div>
  );
};

export default SurveyHero;
