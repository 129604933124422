import React, {useEffect, useMemo, useRef, useState} from "react";
import "./TextInput.scss";
import {Icon, Input, TextField} from "@mui/material";
import getThemes from "../../../../constants/colors/getTheme";
import {useSelector} from "react-redux";
import styled from "@emotion/styled";
import TextInputLabel from "../TextInputLabel/TextInputLabel";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

const TextInput = ({
                       type,
                       label,
                       labelClass = "",
                       value = "",
                       setValue,
                       onKeyDownEvent,
                       mode,
                       disabled,
                       placeholder,
                       rows,
                       errorMessage,
                       isLanding = false,
                       maxlength,
                     specialClass = ""
                   }) => {
    const app = useSelector((state) => state.app);
    const [passwordShown, setPasswordShown] = useState(false);
    const [calculatedType, setCalculatedType] = useState(type);
    const [style, setStyle] = useState({});
    const inputRef = useRef(null);
    const textInputClasses = useMemo(() => {
        if (isLanding) {
            return "TextInput LandingInput";
        } else {
            return "TextInput";
        }
    }, [isLanding]);

    const textAreaClasses = useMemo(() => {
        if (isLanding) {
            return "TextArea LandingInput " + specialClass;
        } else {
            return "TextArea " + specialClass;
        }
    }, [isLanding]);

    useEffect(() => {
        if (mode === "grey") {
            setStyle({
                backgroundColor: "#F4F4F3",
                borderColor: "#F4F4F3",
            });
        }
    }, []);

    const checkKeyDown = (event) => {
        if (type === "password" && event.key === "Enter") {
            onKeyDownEvent();
        }
    };

    const isFocused = () => {
        mode === "grey"
            ? setStyle({
                borderColor: getThemes("mainColor", app.appTheme),
                borderWidth: "1px",
                borderStyle: "solid",
                backgroundColor: "#F4F4F3",
            })
            : setStyle({
                borderColor: getThemes("mainColor", app.appTheme),
                borderWidth: "1px",
                borderStyle: "solid",
            });
    };

    const isBlured = () => {
        mode === "grey" ? setStyle({backgroundColor: "#F4F4F3"}) : setStyle({});
    };

    const togglePassword = (isShown) => {
        if (isShown) {
            setCalculatedType("text");
        } else {
            setCalculatedType("password");
        }

        setPasswordShown(isShown);
        const timeout = setTimeout(() => {
            inputRef.current.focus();
        }, 100);

        return () => {
            clearTimeout(timeout);
        };
    };

    return (
        <div>
            <TextInputLabel labelClass={labelClass} text={label}/>
            <div className={"TextInputContainer"}>
                {type !== "textarea" ? (
                    <Input
                        placeholder={placeholder}
                        disabled={disabled}
                        className={textInputClasses}
                        style={style}
                        onFocus={() => isFocused()}
                        onBlur={() => isBlured()}
                        ref={inputRef}
                        type={calculatedType}
                        value={value}
                        onKeyDown={(e) => checkKeyDown(e)}
                        onChange={(e) => setValue(e.target.value)}
                    />
                ) : (
                    <textarea
                        maxlength={maxlength}
                        rows={rows}
                        placeholder={placeholder}
                        disabled={disabled}
                        className={textAreaClasses}
                        style={style}
                        onFocus={() => isFocused()}
                        onBlur={() => isBlured()}
                        ref={inputRef}
                        value={value}
                        onKeyDown={(e) => checkKeyDown(e)}
                        onChange={(e) => setValue(e.target.value)}
                    />
                )}
                {type === "password" && !passwordShown ? (
                    <VisibilityOutlinedIcon
                        onClick={() => togglePassword(true)}
                        className={"passwordIcon"}
                    />
                ) : null}
                {type === "password" && passwordShown ? (
                    <VisibilityOffOutlinedIcon
                        onClick={() => togglePassword(false)}
                        className={"passwordIcon"}
                    />
                ) : null}
            </div>
            <div className={"errorMessage"}> {errorMessage} </div>
        </div>
    );
};

export default TextInput;
