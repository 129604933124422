import React, { useState } from "react";
import "./MyAccount.scss";
import { Box } from "@mui/material";
import TabLine from "../../../components/Admin Panel/TabLine/TabLine";
import Tab from "../../../components/Admin Panel/Tab/Tab";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AccountSettings from "../AccountSettings/AccountSettings";
import ProfileSettings from "../ProfileSettings/ProfileSettings";

const MyAccount = ({ showGlobalSnackbar }) => {
  const app = useSelector((state) => state.app);
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(0);

  return (
    <div className="MyAccount">
      <Box className={"tabContainer"}>
        <Tab
          text={i18n.t("accountSettings")}
          isActive={page === 0}
          page={0}
          setPage={setPage}
        />
        <Tab
          text={i18n.t("profileSettings")}
          isActive={page === 1}
          page={1}
          setPage={setPage}
        />
      </Box>
      <Box className={"tabLineContainer"}>
        <TabLine total={2} step={page} app={app} />
      </Box>

      {page === 0 ? (
        <AccountSettings
          showGlobalSnackbar={showGlobalSnackbar}
          app={app}
          i18n={i18n}
        />
      ) : (
        <ProfileSettings
          showGlobalSnackbar={showGlobalSnackbar}
          app={app}
          i18n={i18n}
        />
      )}
    </div>
  );
};

export default MyAccount;
