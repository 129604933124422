import React, { useEffect, useState } from "react";
import "./Patient.scss";
import { useNavigate, useParams } from "react-router-dom";
import {
  assignPractitioner,
  deletePatient,
  deleteSurvey,
  getAllPractitioners,
  getPatient,
  getPatientSurveys,
  getSettingsCode,
  sendLinkToEmail,
  updatePatientApi,
} from "../../../../core/axios/services";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Headline from "../../../components/Comon/Headline/Headline";
import { Box } from "@mui/material";
import TextInput from "../../../components/Comon/TextInput/TextInput";
import Button from "../../../components/Comon/Button/Button";
import { resetPopUp, showPopup } from "../../../../redux/actions/app";
import Table from "../../../components/Admin Panel/Table/Table";
import CustomCheckbox from "../../../components/Admin Panel/CustomCheckbox/CustomCheckbox";
import Dropdown from "../../../components/Admin Panel/Dropdown/Dropdown";

const Patient = ({ showGlobalSnackbar }) => {
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);
  const [patient, setPatient] = useState({
    name: "",
    email: "",
    phone: "",
    surveys: [],
    excludeFromStatistics: false,
  });
  const [stringPatient, setStringPatient] = useState("");
  const [link, setLink] = useState("");
  const initialSurveys = [
    { surveyOrder: 1, existing: false, generated: false },
    { surveyOrder: 2, existing: false, generated: false },
    { surveyOrder: 3, existing: false, generated: false },
    { surveyOrder: 4, existing: false, generated: false },
    { surveyOrder: 5, existing: false, generated: false },
    { surveyOrder: 6, existing: false, generated: false },
  ];
  const [surveys, setSurveys] = useState(initialSurveys);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [surveyToDelete, setSurveyToDelete] = useState(null);
  const [practitioners, setPractitioners] = useState([]);

  useEffect(() => {
    if (app.popupStatus === true) {
      switch (app.popUpData?.type) {
        case "patient":
          updatePatient();
          dispatch(resetPopUp());
          break;

        case "deletePatient":
          deletePatientData();
          dispatch(resetPopUp());
          break;

        case "deleteSurvey":
          deleteSurveyApi();
          dispatch(resetPopUp());
          break;
      }
    } else if (app.popupStatus === false) {
      dispatch(resetPopUp());
      setSurveyToDelete(null);
    }
  }, [id, app]);

  useEffect(() => {
    getPatientData();
    getPatientSurveyData();
    getAllPractitioners(user).then((res) => {
      let allPractitioners = res.data;
      allPractitioners.unshift({ id: 0, name: i18n.t("noPractitioner") });
      setPractitioners(allPractitioners);

      console.log(allPractitioners);
    });
  }, [id]);

  const updatePatient = async () => {
    try {
      return await updatePatientApi(JSON.parse(stringPatient), user).then(
        (res) => {
          setPatient(res.data.data);
          showGlobalSnackbar(i18n.t("saved"));
        }
      );
    } catch (error) {
      if (error.response.data.email) {
        showGlobalSnackbar(error.response.data.email);
      } else {
        showGlobalSnackbar(i18n.t("thereWasAnError"));
      }
    }
  };

  const deletePatientData = async () => {
    try {
      return await deletePatient(patient.id, user).then((res) => {
        // const data = {
        //     showPopup: true,
        //     data: {
        //         title: i18n.t("userDeleted"),
        //         confirmButton: false,
        //     }
        // }

        // dispatch(showPopup(data));

        showGlobalSnackbar(i18n.t("userDeleted"));
        navigate("/dashboard");
      });
    } catch (error) {
      showGlobalSnackbar(i18n.t("thereWasAnError"));
    }
  };

  const deleteSurveyApi = async () => {
    try {
      return await deleteSurvey(surveyToDelete, user).then((res) => {
        showGlobalSnackbar(i18n.t("surveyDeleted"));

        const index = surveys.findIndex((s) => s.id === surveyToDelete);

        if (index !== -1) {
          surveys[index].existing = false;
          surveys[index].generated = false;
          surveys[index].createdAt = "";
          setSurveys(surveys);
        }

        setSurveyToDelete(null);
      });
    } catch (error) {
      setSurveyToDelete(null);
      showGlobalSnackbar(i18n.t("thereWasAnError"));
    }
  };

  const getPatientSurveyData = async () => {
    const newSurveys = initialSurveys;
    try {
      return await getPatientSurveys(id, user).then((res) => {
        getSettingsCode(res.data.data[res.data.data.length - 1].id, user).then(
          (settingsRes) => {
            const type = res.data.data[0].type;
            res.data.data.forEach((newSurvey) => {
              const surveyIndex = newSurveys.findIndex((s) => {
                return s.surveyOrder === newSurvey.surveyOrder;
              });

              if (surveyIndex !== -1) {
                newSurveys[surveyIndex] = newSurvey;
                setSurveys([...newSurveys]);
              }
            });

            newSurveys.forEach((survey, index) => {
              if (!survey.type) {
                survey.type = type;
                setSurveys([...newSurveys]);
              }
            });
          }
        );
      });
    } catch (error) {
      showGlobalSnackbar(i18n.t("thereWasAnError"));
    }
  };

  const getPatientData = async () => {
    try {
      return await getPatient(id, user).then((res) =>
        setPatient(res.data.data)
      );
    } catch (error) {
      showGlobalSnackbar(i18n.t("thereWasAnError"));
    }
  };

  const changeName = (name) => {
    patient.name = name;
    setPatient({ ...patient });
  };

  const changeLastName = (lastName) => {
    patient.lastName = lastName;
    setPatient({ ...patient });
  };

  const changePhone = (phone) => {
    patient.phone = phone;
    setPatient({ ...patient });
  };

  const changeEmail = (email) => {
    patient.email = email;
    setPatient({ ...patient });
  };

  const generateLink = (index) => {
    getSettingsCode(surveys[index - 1].id, user).then((res) => {
      const code = res.data.data.code;
      setLink(
        process.env.REACT_APP_SURVEYLINKNEXT +
          "/survey-next/" +
          code +
          "/" +
          surveys[index - 1].id
      );
    });
  };

  const copyLink = (index) => {
    navigator.clipboard.writeText(link);
    showGlobalSnackbar(i18n.t("linkCopied"));
  };

  const deleteSurveyStart = (id) => {
    setSurveyToDelete(id);
    const data = {
      showPopup: true,
      data: {
        title: i18n.t("confirmSurveyDelete"),
        confirmButton: i18n.t("confirmSurveyDeleteButton"),
        denyButton: i18n.t("denySurveyDeleteButton"),
        type: "deleteSurvey",
      },
    };
    dispatch(showPopup(data));
  };

  const sendToEmail = (index) => {
    sendLinkToEmail(link, surveys[index - 1].patientId, user);
    showGlobalSnackbar(i18n.t("linkSent"));
  };

  const savePatient = () => {
    if (patient.phone?.trim().length > 0) {
      if (!patient.phone.includes("+")) {
        showGlobalSnackbar(i18n.t("pleaseInsertFullPhoneNumber"));
        return;
      }
    }
    setStringPatient(JSON.stringify(patient));
    const data = {
      showPopup: true,
      data: {
        title: i18n.t("confirmPatient"),
        confirmButton: i18n.t("confirmPatientButton"),
        denyButton: i18n.t("denyPatientButton"),
        type: "patient",
      },
    };
    dispatch(showPopup(data));
  };

  const deletePatientStart = () => {
    const data = {
      showPopup: true,
      data: {
        title: i18n.t("confirmPatientDelete"),
        confirmButton: i18n.t("confirmPatientDeleteButton"),
        denyButton: i18n.t("denyPatientDeleteButton"),
        type: "deletePatient",
      },
    };
    dispatch(showPopup(data));
  };

  const updateExclude = (value) => {
    patient.excludeFromStatistics = value;
    setPatient({ ...patient });
  };

  const assignPractitionerStart = (practitioner) => {
    const postData = {
      patientId: patient.id,
      practitionerId: practitioner.id,
    };

    patient.practitioner = practitioner;
    setPatient({ ...patient });

    try {
      assignPractitioner(user, postData).then((res) => {
        showGlobalSnackbar(i18n.t("saved"));
      });
    } catch (error) {
      showGlobalSnackbar(i18n.t("thereWasAnError"));
    }
  };
  return (
    <div className="Patient">
      <Headline
        sx={{ marginBottom: "60px", textAlign: "left !important;" }}
        text={
          i18n.t("patientInformation") +
          " " +
          patient.name +
          " " +
          patient.lastName
        }
        type={"Headline-big-left"}
      />

      <Box className={"sectionChange"}>
        <Table
          app={app}
          perPage={6}
          sortDirection={"asc"}
          type={"surveys"}
          i18n={i18n}
          total={6}
          sendEmail={sendToEmail}
          generateLink={generateLink}
          copyLink={copyLink}
          setData={setSurveys}
          deleteSurvey={deleteSurveyStart}
          page={1}
          data={surveys}
          showGlobalSnackbar={showGlobalSnackbar}
        />
      </Box>

      <Box className={"sectionChange"}>
        <Headline text={i18n.t("patientOverview")} type={"Headline-medium"} />

        <Box className={"textInputContainer"}>
          <Box className={"inputRow"}>
            <Box className={"smallTextInput"}>
              <TextInput
                type={"text"}
                setValue={(data) => changeName(data)}
                label={i18n.t("name")}
                value={patient.name}
                mode={"grey"}
              />
            </Box>
            <Box className={"smallTextInput"}>
              <TextInput
                type={"text"}
                setValue={(data) => changeLastName(data)}
                label={i18n.t("lastName")}
                value={patient.lastName}
                mode={"grey"}
              />
            </Box>
          </Box>
          <Box className={"inputRow"}>
            <Box className={"smallTextInput"}>
              <TextInput
                type={"text"}
                setValue={(data) => changeEmail(data)}
                label={i18n.t("email")}
                value={patient.email}
                mode={"grey"}
              />
            </Box>
            <Box className={"smallTextInput"}>
              <TextInput
                type={"text"}
                setValue={(data) => changePhone(data)}
                label={i18n.t("phone")}
                value={patient.phone}
                mode={"grey"}
              />
            </Box>
          </Box>
          <Box className={"inputRow"}>
            <CustomCheckbox
              value={patient.excludeFromStatistics}
              setValue={(v) => updateExclude(v)}
              text={i18n.t("excludeFromStatistics")}
            />
          </Box>
          <Box className={"buttonContainer"} onClick={() => savePatient()}>
            <Button text={i18n.t("editPatientProfile")} mode={"small"} />
          </Box>
        </Box>
      </Box>

      <Box className={"sectionChange"}>
        <Headline
          text={i18n.t("assignPatientToPractitioner")}
          type={"Headline-medium"}
        />
        <br />
        <div style={{ width: "300px" }}>
          <Dropdown
            options={practitioners}
            setSelectedOption={(selected) => assignPractitionerStart(selected)}
            label={i18n.t("selectPractitioner")}
            type={"practitionerBulk"}
            selectedOption={patient.practitioner}
            placeholder={i18n.t("selectPractitioner")}
          />
        </div>
      </Box>

      {user.user.type === 2 ? (
        <Box className={"sectionChange bottomSection"}>
          <Headline
            text={i18n.t("deletePatientProfile")}
            type={"Headline-medium"}
          />
          <div className={"deleteWarning"}>
            {i18n.t("deletePatientProfileConformation")}
          </div>

          <div className={"deleteItems"}>
            <div className={"deleteItem"}>{i18n.t("deletePatientInfo1")}</div>
            <div className={"deleteItem"}>{i18n.t("deletePatientInfo2")}</div>
            <div className={"deleteItem"}>{i18n.t("deletePatientInfo3")}</div>
            <div className={"deleteItem"}>{i18n.t("deletePatientInfo4")}</div>
          </div>

          <div className={"deleteWarning"}>{i18n.t("actionUndone")}</div>
          <Box
            className={"buttonContainer"}
            onClick={() => deletePatientStart()}
          >
            <Button text={i18n.t("deleteCenterProfile")} mode={"small"} />
          </Box>
        </Box>
      ) : null}
    </div>
  );
};
export default Patient;
